// App URL
export const APP_URL = 'https://app.budget-master.space';

// Routes
export const LOGIN_ROUTE = '/';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const REGISTER_ROUTE = '/register';
export const DASHBOARD_ROUTE = '/dashboard';
export const BUDGETS_ROUTE = '/budgets';
export const TRY_APP_ROUTE = '/try-app';
export const BUDGET_EDITOR_PAGE_ROUTE = '/budget-editor';
export const CREATE_RECORD_ROUTE = '/create-record';
export const EDIT_RECORD_ROUTE = '/edit-record';

// Complete URLs
export const LOGIN_APP_ROUTE = `${APP_URL}${LOGIN_ROUTE}`;
export const TRY_APP_COMPLETE_ROUTE = `${APP_URL}${TRY_APP_ROUTE}`;
export const REGISTER_APP_COMPLETE_ROUTE = `${APP_URL}${REGISTER_ROUTE}`;
export const FORGOT_PASSWORD_COMPLETE_ROUTE = `${APP_URL}${FORGOT_PASSWORD_ROUTE}`;
export const DASHBOARD_COMPLETE_ROUTE = `${APP_URL}${DASHBOARD_ROUTE}`;
